@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPro/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/fonts/SFPro/SFProDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPro/SFProDisplay-Semibold.woff") format("woff"),
    url("./assets/fonts/SFPro/SFProDisplay-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPro/SFProDisplay-Bold.woff") format("woff"),
    url("./assets/fonts/SFPro/SFProDisplay-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
